.services-section {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .services-section .services-header {
    margin-bottom: 50px;
    font-size: 25px;
  }
  .services-section .services-header .services-header-title {
    margin-bottom: 15px;
  }
  .services-section .services-row-tail {
    margin-top: 50px;
  }
  .services-section .services-icon {
    color: #1bbd9b;
    margin-bottom: 30px;
  }
  .services-section .services-title {
    margin-bottom: 15px;
    color: #ffbbbb;
    font-weight: bold;
  }
  .services-section .services-desc {
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    .services-section {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .services-section .services-group {
      margin-bottom: 50px;
    }
    .services-section .services-row-tail {
      margin-top: 0;
    }
  }
  .services-icon .fa:hover{
      padding: 0 10px 0 10px;
      color: white;
      background: #1BBD9B;
      border-radius: 25px; 
      transition:.4s;  
  }
  .services-group{
    padding: 10px;
    background:#061F20;
    color: white;
    height: 100%;
  }
  .service-icon{
    color: white;
  }
