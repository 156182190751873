.navbar125 {
    overflow-x: hidden;
	overflow-y: hidden;
	position: fixed;
	top: 0;
	width: 100%;
	background: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url("../images/rm21.jpg");
	z-index: 6;
	-webkit-box-shadow:0px 5px 5px #aaaaaa;
	-moz-box-shadow:0px 5px 5px #aaaaaa;
	box-shadow:0px 5px 5px #aaaaaa;
}

.office-logo {
	z-index: 10;
	position: absolute;
	top: -15px;
	left: 0;
}

.moving-logo {
	@media (max-width: 1500px) {
		display: none;
	}
}

.phone-number {
	@media (max-width: 1050px) {
		display: none;
	}
}
