
.signup-mdb-card {
  box-shadow: 5px 5px 5px 5px lightgray;
}

.signup-mdb-container {
  display: inline-block;
  float: left;
  width: 600px !important;
  padding: 100px 100px 100px 150px;
}

.signup-input-field {
  width: 240px;
}

.error-input-field {
  border: solid red 2px;
}

.li-validation-error {
  text-align: left !important;
}

.signup-form-wrapper {
  min-width: 1400px;
}