
.outer-academy-name-wrapper {
  padding: 300px 0 0 5%;
  display: inline-block;
  text-align: center;
  width: 900px;
}

.inner-academy-name-wrapper {
  color: blue;
  font-size: 55px;
  font-weight: bold;
  margin-top:auto;
  margin-bottom:auto;
  text-align:center;
}

.inner-academy-body-wrapper {
  font-weight: bold;
  font-size: 35px;
  margin-top:auto;
  margin-bottom:auto;
  text-align:center;

}
